import React from "react"
import slug from "slug"

/* Import Global Component(s) */
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

/* Import Global Asset(s) */
import CrossSvgSrc from "~assets/images/close.svg"

/* Import Local Style(s) */
import "./legal-header.scss"

const LegalHeader = ({ location, title, order }) => {
  const prevUrlPath = location.state?.prevUrlPath

  return (
    <header className="post-header legal-header">
      <h1>
        { order.map(l => (
            <Link className={(title !== l.pageTitle) ? "inactive" : ""} key={slug(l.pageTitle)} to={'/legal/' + slug(l.pageTitle)}>{l.pageTitle}</Link>
          ))
        }
      </h1>
      <Link className="close" to={(prevUrlPath && !prevUrlPath.includes('legal')) ? prevUrlPath : "/"}>
        <img src={CrossSvgSrc} alt="Cross" />
      </Link>
    </header>
  )
}

export default LegalHeader
